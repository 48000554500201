import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'components/elements/Typography';
import IconCustom from 'components/helpers/IconCustom';

import { IPropsArticleDisclaimer } from './models';

import './ArticleDisclaimer.scss';

const ArticleDisclaimer: FC<IPropsArticleDisclaimer> = ({ data: [data], className }) => (
  <div data-testid="ArticleDisclaimer" className={classNames('article-disclaimer', className)}>
    {data?.icon ? <IconCustom icon={data.icon} className="article-disclaimer__icon" /> : null}
    <Typography data={data.text} customClass="article-disclaimer__text" />
  </div>
);

export default ArticleDisclaimer;
