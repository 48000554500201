import React, { FC, useCallback, useState } from 'react';

import Accordion from 'components/elements/Accordion';
import BaseSection from 'components/helpers/BaseSection';
import ScrollWrapper from 'components/helpers/ScrollWrapper';

import { IPropsFaqInfo } from './models';

import './FaqInfo.scss';

const FaqInfo: FC<IPropsFaqInfo> = ({ data }) => {
  const openedIndexFromCMS = data[0].accordion[0].items.findIndex((item) => item.isOpened === '1');
  const [accordionIndex, setAccordionIndex] = useState<number>(openedIndexFromCMS);

  const onChangeCallback = useCallback((selectedIndex: number) => {
    setAccordionIndex((prevState) => (selectedIndex === prevState ? -1 : selectedIndex));
  }, []);

  return (
    <ScrollWrapper name={data[0]?.navigationName || ''}>
      <BaseSection data={data[0].sectionSettings} isFullWidth>
        <div data-testid="FaqInfo" className="faq-info">
          <Accordion
            accordion={data[0].accordion[0]}
            scrollTabIntoView
            selectedIndex={accordionIndex}
            onChange={onChangeCallback}
            changeOnClick={false}
          />
        </div>
      </BaseSection>
    </ScrollWrapper>
  );
};

export default FaqInfo;
