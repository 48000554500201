import React, { FC, useMemo } from 'react';
import { UmbracoProductCard } from '@shared/types';

import ProductCard from 'components/cards/ProductCard';
import Typography from 'components/elements/Typography';
import { sortByOrderUrls } from 'utils/sorting';

import { IPropsRecommendedProducts } from './models';

import './RecommendedProducts.scss';

const RecommendedProducts: FC<IPropsRecommendedProducts> = ({
  data: [data],
  recommendedProductsByLink,
  recommendedProductsByTag,
}) => {
  const sortProductsByIndex = useMemo(
    () => sortByOrderUrls(recommendedProductsByLink, data.selectedProductsLinks),
    [data, recommendedProductsByLink]
  );

  const itemsToRender =
    data?.selectedProductsMode === 'manual' && recommendedProductsByLink?.length
      ? sortProductsByIndex
      : recommendedProductsByTag;

  return (
    <div data-testid="RecommendedProducts" className="recommended-products">
      <Typography data={data.title} customClass="recommended-products__title" />
      {itemsToRender?.map((product: UmbracoProductCard.IStructure) => (
        <ProductCard data={product} variant="vertical" key={product.url} />
      ))}
    </div>
  );
};

export default RecommendedProducts;
