import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { setInlineColorStyle } from 'components/atoms/ColorPicker/helpers';
import { UmbracoColorRules } from 'components/atoms/ColorPicker/models';

import Typography from '../Typography';
import { IPropsTaggedLink } from './models';

import './TaggedLink.scss';

const TaggedLink: FC<IPropsTaggedLink> = ({ data, className }) => {
  const { url, queryString } = data.url[0];
  const colorData = data?.backgroundColor?.[0]?.color;
  const path = queryString && queryString !== '' ? `${url}${queryString}` : url;

  const conditionalInlineColor =
    colorData && setInlineColorStyle(colorData, UmbracoColorRules.backgroundColor);

  return (
    <Link
      to={path}
      aria-label={data.text[0].typographyAriaLabel || data.text[0].text}
      data-testid="TaggedLink"
      className={classNames('tagged-link', className, {
        [`tagged-link-bgc-${colorData?.label}`]: colorData?.label,
      })}
      {...conditionalInlineColor}
    >
      <Typography data={data.text} />
    </Link>
  );
};
export default TaggedLink;
